@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body::-webkit-scrollbar {
  display: none;
}
body::-webkit-scrollbar {
  background-image: url(./Images/landingpage.mp4);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  height: 100vh;
  width: 100vh;
}
#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.navbar {
  position: absolute;
  width: 100%;
  height: 50px;
  top: 2%;
  left: 0%;

  display: flex;
  flex-direction: row;

  opacity: 1;
}
.black {
  position: absolute;
  width: 100%;
  height: 150px;
  top: 0%;
  left: 0%;

  display: flex;
  flex-direction: row;

  opacity: 1;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.78) 148.89%
  );
  transform: rotate(180deg);
}
.mobile-navbar {
  display: none;
}

.logo {
  position: absolute;
  width: 187.21px;
  height: 46px;
  left: 3%;
  top: 10%;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 13.6292px;
}

.navbar_links {
  position: absolute;
  top: 30%;
  width: 70%;
  left: 28%;
  display: flex;
  flex-direction: row;
  gap: 5%;
  z-index: 999;
  float: right;
}

.navbar_links > a {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  text-decoration: none;
}
.navbar_links > a > span {
  font-size: 10px;
}
.navbar_links_login {
  position: absolute;
  top: 30%;
  width: 70%;
  left: 78%;
  display: flex;
  flex-direction: row;
  gap: 10%;
  z-index: 999;
  float: right;
}

.navbar_links_login > a {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  text-decoration: none;
}

.wallet {
  position: absolute;

  left: 83%;
  top: 15%;
}
.wallet > img {
  width: 20vh;
  height: 6vh;
}
.wallet > a {
  position: relative;
  color: #ffffff;
  text-decoration: none;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  text-align: center;
  letter-spacing: -0.01em;
  left: 18%;
  top: 18%;
}
.download {
  position: absolute;
  top: 32%;
  width: 70%;
  left: 65%;
  display: flex;
  flex-direction: row;
  gap: 2%;
}
.download > a {
  position: relative;
  color: #ffffff;
  text-decoration: none;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;

  text-align: center;
  letter-spacing: -0.01em;
  left: -4%;
  top: 15.5%;
}
.download > img {
  position: absolute;
  width: 15px;
  height: 15px;
  left: 3%;
  top: 20%;
}
.container {
  display: flex;
  flex-direction: column;
}
.angel,
#angel {
  display: flex;
  justify-content: center;
}
.angel > img,
.angel > video,
#angel > video {
  position: absolute;

  /* left: 33%; */
  top: -2%;
  width: 500px;
  height: 601px;
}
.para-1 {
  display: flex;
  justify-content: center;
}
.para-1 > img {
  position: absolute;
  width: 450px;
  height: 120px;
  top: 66%;
  /* left: 34%; 
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 40px;
  color: black; */
}
.taro {
  position: absolute;
  width: 538px;
  height: 115px;
  left: 33%;
  top: 67%;
}

.explore > a {
  display: flex;
  justify-content: center;
}
.explore > a > img {
  position: absolute;
  width: 500px;
  /* height: 115px; */
  /* left: 43%; */
  top: 83%;
  /* left: 50%; */
  width: 180px;
}

@media only screen and (max-width: 1250px) and (min-width: 900px) and (max-device-width: 1250px) and (min-device-width: 900px) {
  .logo {
    position: absolute;
    width: 187.21px;
    height: 46px;
    left: 38%;
    top: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 13.6292px;
  }
  .navbar_links_login {
    display: none;
  }
  .navbar_links,
  .wallet,
  .download {
    position: absolute;
    display: none;
  }

  .angel > img,
  .angel > video {
    position: absolute;
    width: 550px;
    height: 661px;
    top: 10%;
  }
  .para-1 {
    text-align: center;
  }
  .para-1 > img {
    position: absolute;
    width: 380px;
    height: 100px;

    /* left: 33%; */

    top: 50%;

    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: black;
  }
  .taro {
    position: absolute;
    width: 338px;
    height: 115px;
    left: 12%;
    top: 70%;
  }
  .taro > img {
    height: 50px;
    width: 300px;
  }
  .explore {
    text-align: center;
  }
  .explore > a > img {
    position: absolute;
    width: 240px;
    height: 80px;
    top: 60%;
  }
}

@media only screen and (max-width: 899px) and (min-width: 500px) {
  .logo {
    position: absolute;
    width: 187.21px;
    height: 46px;
    left: 8%;
    top: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 13.6292px;
  }
  .navbar_links,
  .wallet,
  .download {
    position: absolute;
    display: none;
  }
  .angel {
    display: flex;
    justify-content: center;
    /* left: 35%; */
    top: 5%;
  }
  .angel > img {
    position: absolute;
    width: 250px;
    height: 361px;
  }
  .para-1 {
    display: flex;
    justify-content: center;
  }
  .para-1 > img {
    position: absolute;
    width: 320px;
    height: 90px;
    /* left: 33%; */
    top: 67%;

    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 40px;
    color: black;
  }
  .taro {
    position: absolute;
    width: 338px;
    height: 115px;
    left: 33%;
    top: 60%;
  }
  .taro > img {
    height: 50px;
    width: 300px;
  }
  .explore > a {
    display: flex;
    justify-content: center;
  }
  .explore > a > img {
    position: absolute;

    width: 180px;
    height: 60px;
    /* left: 37%; */
    top: 82%;
  }
}

@media only screen and (max-width: 490px) and (min-width: 380px) and (max-device-width: 490px) and (min-device-width: 380px) {
  .logo {
    position: absolute;
    width: 187.21px;
    height: 46px;
    left: 25%;
    top: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 13.6292px;
  }
  .navbar_links_login {
    display: none;
  }
  .navbar_links,
  .wallet,
  .download {
    position: absolute;
    display: none;
  }
  .angel {
    text-align: center;
    /* left: 5%; */
  }
  .angel > img,
  .angel > video {
    position: absolute;
    top: 20%;
    width: 350px;
    height: 461px;
  }
  .para-1 {
    text-align: center;
  }
  .para-1 > img {
    position: absolute;
    width: 330px;
    height: 100px;
    top: 60%;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: black;
  }
  .taro {
    position: absolute;
    width: 338px;
    height: 115px;
    left: 12%;
    top: 70%;
  }
  .taro > img {
    height: 50px;
    width: 300px;
  }
  .explore {
    text-align: center;
  }
  .explore > a > img {
    position: absolute;
    width: 180px;
    height: 60px;
    top: 73%;
  }
}

@media only screen and (max-width: 630px) and (min-width: 490px) and (max-device-width: 630px) and (min-device-width: 490px) {
  .logo {
    position: absolute;
    width: 187.21px;
    height: 46px;
    left: 38%;
    top: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 13.6292px;
  }
  .navbar_links_login {
    display: none;
  }
  .navbar_links,
  .wallet,
  .download {
    position: absolute;
    display: none;
  }
  .angel {
    text-align: center;

    /* left: 13%; */
  }
  .angel > img,
  .angel > video {
    position: absolute;
    top: 12%;
    width: 450px;
    height: 561px;
  }
  .para-1 {
    text-align: center;
  }
  .para-1 > img {
    position: absolute;
    width: 340px;
    height: 100px;

    /* left: 18%; */

    top: 60%;

    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: black;
  }
  .taro {
    position: absolute;
    width: 338px;
    height: 115px;
    left: 12%;
    top: 70%;
  }
  .taro > img {
    height: 50px;
    width: 300px;
  }
  .explore {
    text-align: center;
  }
  .explore > a > img {
    position: absolute;
    width: 540px;
    height: 540px;
    left: 33%;
    top: 75%;
    width: 200px;
  }
}

@media only screen and (max-width: 750px) and (min-width: 630px) and (max-device-width: 750px) and (min-device-width: 630px) {
  .logo {
    position: absolute;
    width: 187.21px;
    height: 46px;
    left: 38%;
    top: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 13.6292px;
  }
  .navbar_links_login {
    display: none;
  }
  .navbar_links,
  .wallet,
  .download {
    position: absolute;
    display: none;
  }
  .angel {
    position: absolute;

    left: 11%;
    top: 10%;
  }
  .angel > img,
  .angel > video {
    width: 550px;
    height: 661px;
  }

  .para-1 > img {
    position: absolute;
    width: 340px;
    height: 100px;

    left: 23%;

    top: 65%;

    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: black;
  }
  .taro {
    position: absolute;
    width: 338px;
    height: 115px;
    left: 12%;
    top: 70%;
  }
  .taro > img {
    height: 50px;
    width: 300px;
  }
  .explore {
    position: absolute;
    width: 540px;
    height: 540px;
    left: 33%;
    top: 78%;
  }
  .explore > img {
    width: 220px;
  }
}

@media only screen and (max-width: 900px) and (min-width: 750px) and (max-device-width: 900px) and (min-device-width: 750px) {
  .logo {
    position: absolute;
    width: 187.21px;
    height: 46px;
    left: 38%;
    top: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 13.6292px;
  }

  .navbar_links_login {
    display: none;
  }
  .navbar_links,
  .wallet,
  .download {
    position: absolute;
    display: none;
  }
  .angel {
    text-align: center;
  }
  .angel > img,
  .angel > video {
    position: absolute;

    /* left: 19%; */
    top: 10%;
    width: 550px;
    height: 661px;
  }
  .para-1 {
    text-align: center;
  }
  .para-1 > img {
    position: absolute;
    width: 440px;
    height: 120px;

    /* left: 32%; */

    top: 55%;

    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: black;
  }
  .taro {
    position: absolute;
    width: 338px;
    height: 115px;
    left: 12%;
    top: 70%;
  }
  .taro > img {
    height: 50px;
    width: 300px;
  }
  .explore {
    text-align: center;
  }
  .explore > a > img {
    width: 300px;
    position: absolute;
    /* width: 540px; */
    height: 97px;
    /* left: 41%; */
    top: 68%;
  }
}

@media only screen and (max-width: 379px) and (min-width: 300px) and (max-device-width: 379px) and (min-device-width: 300px) {
  .logo {
    position: absolute;
    width: 187.21px;
    height: 46px;
    left: 23%;
    top: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 13.6292px;
  }
  .navbar_links_login {
    display: none;
  }
  .navbar_links,
  .wallet,
  .download {
    position: absolute;
    display: none;
  }
  .angel {
    text-align: center;
  }
  .angel > img,
  .angel > video {
    position: absolute;

    /* left: 3%; */
    top: 5%;
    width: 350px;
    height: 461px;
  }
  .para-1 {
    text-align: center;
  }
  .para-1 > img {
    position: absolute;
    width: 320px;
    height: 90px;

    /* left: 10%; */

    top: 60%;

    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: black;
  }
  .taro {
    position: absolute;
    width: 338px;
    height: 115px;
    left: 12%;
    top: 70%;
  }
  .taro > img {
    height: 50px;
    width: 300px;
  }
  .explore {
    text-align: center;
  }
  .explore > a > img {
    position: absolute;
    width: 170px;
    height: 55px;
    /* left: 25%; */
    top: 74%;
  }
}

@media only screen and (max-width: 299px) and (min-width: 1px) and (max-device-width: 299px) and (min-device-width: 1px) {
  .logo {
    position: absolute;
    width: 187.21px;
    height: 46px;
    left: 17%;
    top: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 13.6292px;
  }
  .navbar_links_login {
    display: none;
  }
  .navbar_links,
  .wallet,
  .download {
    position: absolute;
    display: none;
  }
  .angel {
    text-align: center;
  }
  .angel > img,
  .angel > video {
    position: absolute;

    /* left: -8%; */
    top: 7%;
    width: 350px;
    height: 461px;
  }
  .para-1 {
    text-align: center;
  }
  .para-1 > img {
    position: absolute;
    width: 250px;
    height: 80px;
    /* left: 1%; */

    top: 60%;

    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: black;
  }
  .taro {
    position: absolute;
    width: 338px;
    height: 115px;
    left: 12%;
    top: 70%;
  }
  .taro > img {
    height: 50px;
    width: 300px;
  }
  .explore {
    text-align: center;
  }
  .explore > a > img {
    position: absolute;
    width: 138px;
    height: 50px;
    /* left: 20%; */
    top: 75%;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 1px) {
  .mobile-navbar {
    display: block;
    position: absolute;
    width: 40vh;
    height: 100vh;
    z-index: 9999;
    top: -3%;
    background: rgba(255, 255, 255, 0.03);
    box-shadow: inset 0px 4px 68px rgba(255, 255, 255, 0.09),
      inset 0px 2px 4px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(88px);
  }

  .mobile-navbar a {
    color: white;
    padding: 30px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
  }

  .mobile-navbar a:hover {
    color: black;
  }
  .mobile-navbar-links > a {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
    text-decoration: none;
  }
  .mobile-navbar-links-two > a {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
    text-decoration: none;
  }
  .mobile-navbar-links > a > img {
    width: 225px;
    height: 60px;
  }
  .mobile-logo {
    position: absolute;
    width: 150.21px;
    height: 40px;
    left: 4%;
    top: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .mobile-logo > img {
    width: 150px;
  }
  .mobile-navbar-links {
    margin-top: 40%;
    position: absolute;
    left: 9%;
    text-align: center;
    height: 60vh;
  }
  .mobile-navbar-links-two {
    margin-top: 19%;
    position: absolute;
    left: 34%;
    text-align: center;
    height: 50vh;
    display: flex;
    flex-direction: column;
  }
  .mobile-navbar-line {
    position: absolute;
    width: 50%;
    height: 0px;
    left: 23%;
    top: 34%;

    opacity: 0.2;
    border: 1px solid #ffffff;
    transform: rotate(-180deg);
  }
  .mobile-navbar-login {
    position: absolute;
    text-align: center;
    left: 35%;
    top: 15%;
    font-weight: bold;
  }
  .mobile-navbar-loa {
    position: absolute;
    top: 0%;
    left: 30% !important;
    margin-left: 5%;
  }
  .mobile-navbar-lob {
    position: absolute;
    top: 14%;
    left: 20%;
  }
  .mobile-navbar-loc {
    position: absolute;
    top: 28%;
    left: 20%;
  }
  .mobile-navbar-lod {
    position: absolute;
    top: 42%;
    left: 20%;
  }
  .mobile-navbar-loe {
    position: absolute;
    top: 56%;
    left: 20%;
  }
  .mobile-navbar-lof {
    position: absolute;
    top: 70%;
    left: 20%;
  }
  .mobile-navbar-log {
    position: absolute;
    top: 84%;
    left: 20%;
    display: flex !important;
    flex-direction: row;
    gap: 5px;
  }
  .mobile-navbar-log > span {
    font-size: 15px;
    color: gray;
    position: absolute;
    top: 38%;
    left: 88%;
  }
  .icon {
    position: absolute;
    left: 2.75%;
    top: 4.8%;
    z-index: 999;
  }
  .icon > img {
    height: 20px;
    width: 30px;
  }
  .cross {
    position: absolute;
    left: 82.75%;
    top: 10.5%;
    z-index: 999;
  }
  .cross > img {
    height: 30px;
    width: 30px;
  }
}

@media only screen and (max-width: 1925px) and (min-width: 1600px) {
  .angel,
  #angel {
    position: absolute;

    left: 31%;
    top: -2%;
  }
  .angel > img,
  .angel > video,
  #angel > video {
    width: 700px;
    height: 801px;
  }
  .para-1 {
    display: flex;
    justify-content: center;
  }
  .para-1 > img {
    position: absolute;
    width: 450px;
    height: 120px;
    /* left: 37%; */
    top: 66%;

    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 40px;
    color: black;
  }
  .taro {
    position: absolute;
    width: 538px;
    height: 115px;
    left: 48%;
    top: 67%;
  }
  .explore > a {
    display: flex;
    justify-content: center;
  }
  .explore > a > img {
    position: absolute;
    width: 500px;
    /* height: 115px; */
    /* left: 44%; */
    top: 83%;
    width: 180px;
  }
  .download > img {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 2.5%;
    top: 20%;
  }
  .download {
    position: absolute;
    top: 32%;
    width: 70%;
    left: 61%;
    display: flex;
    flex-direction: row;
    gap: 2%;
  }
}

@media only screen and (max-width: 1290px) and (min-width: 1270px) {
  .download > img {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 4.5%;
    top: 20%;
  }
  .download {
    position: absolute;
    top: 32%;
    width: 70%;
    left: 67%;
    display: flex;
    flex-direction: row;
    gap: 2%;
  }
}

@media only screen and (width: 540px) and (device-width: 540px) and (height: 720px) and (device-height: 720px) {
  .logo {
    position: absolute;
    width: 187.21px;
    height: 46px;
    left: 38%;
    top: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 13.6292px;
  }
  .navbar_links_login {
    display: none;
  }
  .navbar_links,
  .wallet,
  .download {
    position: absolute;
    display: none;
  }
  .angel {
    text-align: center;
    /* left: 5%; */
  }
  .angel > img,
  .angel > video {
    position: absolute;
    top: -3%;
    width: 500px;
    height: 611px;
  }
  .para-1 {
    text-align: center;
  }
  .para-1 > img {
    position: absolute;
    width: 340px;
    height: 100px;

    /* left: 20%; */

    top: 65%;

    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: black;
  }
  .taro {
    position: absolute;
    width: 338px;
    height: 115px;
    left: 12%;
    top: 70%;
  }
  .taro > img {
    height: 50px;
    width: 300px;
  }
  .explore {
    text-align: center;
  }
  .explore > a > img {
    width: 190px;
    position: absolute;
    /* width: 540px; */
    height: 65px;
    /* left: 32%; */
    top: 80%;
  }
}

@media only screen and (width: 912px) and (device-width: 912px) and (height: 1368px) and (device-height: 1368px) {
  .logo {
    position: absolute;
    width: 187.21px;
    height: 46px;
    left: 38%;
    top: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 13.6292px;
  }
  .navbar_links_login {
    display: none;
  }
  .navbar_links,
  .wallet,
  .download {
    position: absolute;
    display: none;
  }
  .angel {
    /* display: block; */
    /* left: 20%; */
    text-align: center;
  }
  .angel > img,
  .angel > video {
    position: absolute;
    width: 600px;
    height: 711px;
    top: 5%;
  }
  .para-1 {
    text-align: center;
  }
  .para-1 > img {
    position: absolute;
    width: 590px;
    height: 180px;

    top: 50%;

    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: black;
  }
  .taro {
    position: absolute;
    width: 338px;
    height: 115px;
    left: 12%;
    top: 70%;
  }
  .taro > img {
    height: 50px;
    width: 300px;
  }
  .explore {
    text-align: center;
    left: 0;
  }
  .explore > a > img {
    position: absolute;
    width: 300px;
    height: 110px;
    top: 65%;
  }
}

@media only screen and (width: 1024px) and (device-width: 1024px) and (height: 600px) and (device-height: 600px) {
  .logo {
    position: absolute;
    width: 187.21px;
    height: 46px;
    left: 38%;
    top: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 13.6292px;
  }
  .navbar_links_login {
    display: none;
  }
  .navbar_links,
  .wallet,
  .download {
    position: absolute;
    display: none;
  }
  .angel {
    text-align: center;
  }
  .angel > img,
  .angel > video {
    position: absolute;

    top: -3%;
    width: 400px;
    height: 511px;
  }
  .para-1 {
    text-align: center;
  }
  .para-1 > img {
    position: absolute;
    width: 340px;
    height: 100px;

    /* left: 32%; */

    top: 65%;

    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: black;
  }
  .taro {
    position: absolute;
    width: 338px;
    height: 115px;
    left: 12%;
    top: 70%;
  }
  .taro > img {
    height: 50px;
    width: 300px;
  }
  .explore {
    text-align: center;
  }
  .explore > a > img {
    position: absolute;
    width: 180px;
    height: 60px;
    top: 83%;
  }
}
