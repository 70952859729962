body {
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}
.home_banner_explore {
  /* text-align: center; */
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 1100px) and (min-device-width: 1100px) {
  .home_taro_para_mobile,
  .home_nft_chest_mobile,
  .home_nfthero_mobile,
  .home_tarotoken_mobile,
  .home_roadmap_table_mobile,
  .home_partner_mobile,
  .icon {
    display: None;
  }
}
/* Desktop View */
@media only screen and (min-width: 0px) and (max-width: 1100px) {
  .home_taro_para,
  .home_nft_chest,
  .home_nfthero,
  .home_tarotoken,
  .home_roadmap_table,
  .home_partner,
  .navbar_links,
  .wallet {
    display: None;
  }

  html,
  body {
    overflow-x: hidden;
  }
  body {
    overflow-y: scroll;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 5%;
    top: 11.5%;
  }
  .home_banner_map_new {
    position: absolute;
    left: 5%;
    top: 11.5%;
    opacity: 0.1;
  }
  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 28%;
    top: 5%;
  }
  .home_banner_angel > video {
    width: 100%;
    height: 100%;
  }

  /* .home_banner_explore {
    left: 28.5%;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 30vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 120vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 99.8vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 7%;
    left: 20%;
  }
  .home_taro_heading > img {
    width: 75%;
  }
  .home_taro_para {
    display: None;
    position: absolute;
    top: 24%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 200%;
  }
  .home_taro_para_mobile {
    position: absolute;
    top: 20%;
    left: 3%;
  }
  .home_taro_para_mobile > img {
    width: 96%;
  }
  .home_taro_banner {
    position: absolute;
    top: 55%;
    left: 10%;
  }
  .home_taro_banner > img {
    width: 90%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 130vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 210.5vh;
  }

  .home_nft_chest {
    display: None;
    position: absolute;
    left: 12%;
    top: 15%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }
  .home_nft_chest_mobile {
    position: absolute;
    left: 3%;
    top: 10%;
  }
  .home_nft_chest_mobile > img {
    width: 96%;
    height: 40%;
  }
  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 150vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 340vh;
  }
  .home_nfthero_text {
    display: None;
    position: absolute;
    left: 10%;
    top: 30%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    display: None;
    position: absolute;
    left: 55%;
    top: 7%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_nfthero_mobile {
    position: absolute;
    left: 3%;
    top: 6%;
  }
  .home_nfthero_mobile > img {
    width: 96%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 220vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 480vh;
  }
  .home_tarotoken_blur {
    display: None;
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    display: None;
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    display: None;
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }
  .home_tarotoken_mobile {
    position: absolute;
    left: 3%;
    top: 5%;
  }
  .home_tarotoken_mobile > img {
    width: 96%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 200.5vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 699vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 20%;
    top: 4%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 75%;
  }
  .home_roadmap_table {
    display: None;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    display: None;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .home_roadmap_table_mobile {
    position: absolute;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 0%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table_mobile > img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .home_roadmap_table_one {
    height: 100% !important;
    width: 100% !important;
  }
  .home_roadmap_line {
    display: None;
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.96;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 82vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 899vh;
  }
  .home_partner_text {
    display: None;
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    display: None;
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    display: None;
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    display: None;
    width: 50% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 20%;
  }
  .home_partner_mobile {
    position: absolute;
    left: 1%;
    top: 5%;
  }
  .home_partner_mobile > img {
    width: 100%;
    height: 100%;
  }
  .home_bg {
    background-color: black;
    opacity: 1;
    width: 100vw;
    height: 10vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 971vh;
    display: None;
  }
  .home_footer {
    background-image: url("./Images/herobackground_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 35vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 980.8vh;
  }
  .home_footer_hero {
    display: None;
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 20%;
    top: 15%;
  }
  .home_footer_logo > img {
    width: 73%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 46%;

    left: 17%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 4vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 46%;
    gap: 25%;
    top: 46%;
  }
  .home_footer_social_media_logo > a > img {
    width: 5vw;
    height: 5.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 61%;

    left: 7%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 3.3vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 75%;
    left: 12%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.5vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}

.home_banner {
  background-image: url(./Images/bgmap.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
}
.home_banner_map {
  position: absolute;
  left: 4%;
  top: 11.5%;
}

.home_banner_map > img,
.home_banner_map > video {
  height: 87vh;
  width: 88vw;
}

#home-background-video {
  width: 90vw;
  height: 85vh;
  object-fit: cover;
  position: absolute;
  left: 4.5%;
  right: 0;
  top: 10%;
  bottom: 0;
  z-index: 2;
  border-radius: 2%;
}
.home_banner_angel {
  z-index: 2;
  position: absolute;
  left: 70%;
  top: 5%;
}
.home_banner_angel > video {
  width: 100%;
  height: 100%;
}

/* .home_banner_explore {
  position: absolute;

  left: 43%;
  bottom: 10%;
  z-index: 4;
} */
.home_banner_explore > img {
  position: absolute;
  width: 30vh;
  bottom: 10%;
  z-index: 4;
}
.home_taro {
  background-image: url(./Images/bgtaroverse.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 115vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 95vh;
}
.home_taro_heading {
  position: absolute;
  top: 12%;
  left: 38%;
}
.home_taro_heading > img {
  width: 35%;
}
.home_taro_para {
  position: absolute;
  top: 23%;
  left: 8%;
}
.home_taro_para > img {
  width: 90%;
}
.home_taro_banner {
  position: absolute;
  top: 40%;
  left: 26%;
}
.home_taro_banner > img {
  width: 65%;
  height: 65%;
}

.home_nft {
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 210vh;
}

.home_nft_chest {
  position: absolute;
  left: 12%;
  top: 15%;
}
.home_nft_chest > img {
  width: 85%;
  height: 40%;
}

.home_nft_blur {
  position: absolute;
  left: 23%;
  top: -15%;
}
.home_nft_blur > img {
  width: 60%;
  height: 60%;
}

.home_nfthero {
  background-image: url(./Images/bgnfthero.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 300vh;
}
.home_nfthero_text {
  position: absolute;
  left: 10%;
  top: 28%;
}
.home_nfthero_text > img {
  width: 45%;
  height: 90%;
}
.home_nfthero_image {
  position: absolute;
  left: 55%;
  top: 7%;
}
.home_nfthero_image > img {
  width: 80%;
  height: 70%;
}
.home_tarotoken {
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 400vh;
}
.home_tarotoken_blur {
  position: absolute;
  left: -8%;
  top: -55%;
}
.home_tarotoken_blur > img {
  width: 80%;
  height: 80%;
}

.home_tarotoken_para {
  position: absolute;
  left: 8%;
  top: 10%;
}
.home_tarotoken_para > img {
  width: 90%;
  height: 100%;
}

.home_tarotoken_logo {
  position: absolute;
  left: 20%;
  top: 34%;
}
.home_tarotoken_logo > img {
  width: 70%;
  height: 100%;
}

.home_roadmap {
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 110vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 500vh;
}
.home_roadmap_text {
  position: absolute;
  left: 35%;
  top: 5%;
}
.home_roadmap_text > img {
  height: 85%;
  width: 40%;
}
.home_roadmap_table {
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 3%;
  top: 25%;
  justify-content: center;
  /* left: 3%;
  right: 3%; */
}
.home_roadmap_table > img {
  height: 30%;
  width: 15%;
  cursor: pointer;
}
.home_roadmap_table_one {
  height: 18% !important;
  width: 15% !important;
}
.home_roadmap_line {
  position: absolute;
  left: 9%;
  top: 30%;
}
.home_roadmap_line > img {
  width: 90%;
}

.home_roadmap_nav {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 80%;
  right: 15%;
  gap: 30%;
  width: 5%;
  height: 10%;
}
.home_roadmap_nav_left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;

  position: absolute;
  width: 50%;
  height: 50%;

  background: rgba(255, 255, 255, 0.1);
}
.home_roadmap_nav_left > img {
  height: 50%;
  width: 50%;
}
.home_roadmap_nav_right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;

  position: absolute;
  width: 50%;
  height: 50%;
  right: -30%;

  background: rgba(255, 255, 255, 0.1);
}
.home_roadmap_nav_right > img {
  height: 50%;
  width: 50%;
}

.home_partner {
  background-color: black;
  opacity: 0.92;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 70vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 610vh;
}
.home_partner_text {
  position: absolute;
  left: 35%;
  top: 8%;
}
.home_partner_text > img {
  width: 40%;
  height: 100%;
}
.home_partner_investor {
  position: absolute;
  left: 15%;
  top: 30%;
  right: 15%;
  display: flex;
  flex-direction: column;
  column-gap: 15%;
}
.home_partner_investor > img {
  width: 100%;
  height: 50%;
}
.home_partner_investor_two {
  width: 80% !important;
  height: 100% !important;
  position: absolute;
  top: 130%;
  left: 8.5%;
}
.home_bg {
  background-color: black;
  width: 100vw;
  height: 21vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 679vh;
}
.home_footer {
  background-color: rgb(24, 22, 22);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 30vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 700vh;
}
.home_footer_hero {
  position: absolute;
  left: 10%;
  top: -42%;
  opacity: 1;
}
.home_footer_hero > img {
  width: 30%;
  z-index: 99999999;
  opacity: 1;
}
.home_footer_logo {
  position: absolute;
  left: 45%;
  top: 7%;
}
.home_footer_logo > img {
  width: 30%;
}
.home_footer_social_media {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 35%;

  left: 45.2%;
}
.home_footer_social_media > p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1vw;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
}
.home_footer_social_media_logo {
  position: absolute;
  display: flex;
  left: 52%;
  gap: 25%;
  top: 35%;
}
.home_footer_social_media_logo > a {
  z-index: 99999999;
}
.home_footer_social_media_logo > a > img {
  width: 1.25vw;
  height: 1.25vw;
}

.home_footer_footnotes {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 50%;

  left: 40.2%;
}
.home_footer_footnotes > p {
  color: #ffff;
  font-size: 1vw;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
}
.home_footer_term {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 70%;
  left: 42.5%;
}
.home_footer_term > p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1vw;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
}
.home_partner_blur {
  position: absolute;
  left: -22%;
  top: -49%;
}
.home_partner_blur > img {
  width: 80%;
  height: 80%;
}
@media only screen and (max-width: 1300px) and (min-width: 1100px) {
  body {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 4%;
    top: 11.5%;
  }

  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 70%;
    top: 5%;
  }
  .home_banner_angel > video {
    width: 100%;
    height: 100%;
  }

  /* .home_banner_explore {
    position: absolute;

    left: 43%;
    bottom: 10%;
    z-index: 4;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 30vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 95vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 12%;
    left: 38%;
  }
  .home_taro_heading > img {
    width: 35%;
  }
  .home_taro_para {
    position: absolute;
    top: 23%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 90%;
  }
  .home_taro_banner {
    position: absolute;
    top: 40%;
    left: 26%;
  }
  .home_taro_banner > img {
    width: 65%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 185vh;
  }

  .home_nft_chest {
    position: absolute;
    left: 12%;
    top: 15%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }

  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 260vh;
  }
  .home_nfthero_text {
    position: absolute;
    left: 10%;
    top: 28%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    position: absolute;
    left: 55%;
    top: 7%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 340vh;
  }
  .home_tarotoken_blur {
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 90vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 430vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 35%;
    top: 5%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 40%;
  }
  .home_roadmap_table {
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 25%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    height: 30%;
    width: 15%;
    cursor: pointer;
  }
  .home_roadmap_table_one {
    height: 18% !important;
    width: 15% !important;
  }
  .home_roadmap_line {
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.92;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 70vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 520vh;
  }
  .home_partner_text {
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    width: 80% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 8.5%;
  }
  .home_bg {
    background-color: black;
    width: 100vw;
    height: 23vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 580vh;
  }
  .home_footer {
    background-color: rgb(24, 22, 22);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 35vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 603vh;
  }
  .home_footer_hero {
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 45%;
    top: 5%;
  }
  .home_footer_logo > img {
    width: 30%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 28%;

    left: 45.2%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 52%;
    gap: 25%;
    top: 28%;
  }
  .home_footer_social_media_logo > a {
    z-index: 999999;
  }
  .home_footer_social_media_logo > a > img {
    width: 1.25vw;
    height: 1.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 45%;

    left: 40.2%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 1vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 60%;
    left: 42.5%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}
@media only screen and (max-width: 1500px) and (min-width: 1300px) {
  body {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 4%;
    top: 11.5%;
  }

  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 70%;
    top: 5%;
  }
  .home_banner_angel > video {
    width: 100%;
    height: 100%;
  }

  /* .home_banner_explore {
    position: absolute;

    left: 43%;
    bottom: 10%;
    z-index: 4;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 30vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 95vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 12%;
    left: 38%;
  }
  .home_taro_heading > img {
    width: 35%;
  }
  .home_taro_para {
    position: absolute;
    top: 23%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 90%;
  }
  .home_taro_banner {
    position: absolute;
    top: 40%;
    left: 26%;
  }
  .home_taro_banner > img {
    width: 65%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 195vh;
  }

  .home_nft_chest {
    position: absolute;
    left: 12%;
    top: 15%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }

  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 280vh;
  }
  .home_nfthero_text {
    position: absolute;
    left: 10%;
    top: 28%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    position: absolute;
    left: 55%;
    top: 7%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 370vh;
  }
  .home_tarotoken_blur {
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 92vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 460vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 35%;
    top: 5%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 40%;
  }
  .home_roadmap_table {
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 25%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    height: 30%;
    width: 15%;
    cursor: pointer;
  }
  .home_roadmap_table_one {
    height: 18% !important;
    width: 15% !important;
  }
  .home_roadmap_line {
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.92;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 70vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 550vh;
  }
  .home_partner_text {
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    width: 80% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 8.5%;
  }
  .home_bg {
    background-color: black;
    width: 100vw;
    height: 23vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 620vh;
  }
  .home_footer {
    background-color: rgb(24, 22, 22);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 40vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 643vh;
  }
  .home_footer_hero {
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 45%;
    top: 5%;
  }
  .home_footer_logo > img {
    width: 30%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 28%;

    left: 45.2%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 52%;
    gap: 25%;
    top: 28%;
  }
  .home_footer_social_media_logo > a {
    z-index: 999999;
  }
  .home_footer_social_media_logo > a > img {
    width: 1.25vw;
    height: 1.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 45%;

    left: 40.2%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 1vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 60%;
    left: 42.5%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (min-device-width: 380px) and (max-device-width: 425px) and (min-width: 380px) and (max-width: 425px) {
  html,
  body {
    overflow-x: hidden;
  }

  body {
    overflow-y: scroll;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100.5vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 5%;
    top: 11.5%;
  }
  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 26%;
    top: 20%;
  }
  .home_banner_angel > video {
    width: 100%;
    height: 100%;
  }
  /* 
  .home_banner_explore {
    position: absolute;

    left: 24.5%;
    bottom: 10%;
    z-index: 4;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 30vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 80vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 8%;
    left: 20%;
  }
  .home_taro_heading > img {
    width: 75%;
  }
  .home_taro_para {
    display: None;
    position: absolute;
    top: 42%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 200%;
  }
  .home_taro_para_mobile {
    position: absolute;
    top: 20%;
    left: 3%;
  }
  .home_taro_para_mobile > img {
    width: 96%;
  }
  .home_taro_banner {
    position: absolute;
    top: 56%;
    left: 10%;
  }
  .home_taro_banner > img {
    width: 90%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 92vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 172.5vh;
  }

  .home_nft_chest {
    display: None;
    position: absolute;
    left: 12%;
    top: 15%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }
  .home_nft_chest_mobile {
    position: absolute;
    left: 3%;
    top: 10%;
  }
  .home_nft_chest_mobile > img {
    width: 96%;
    height: 40%;
  }
  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 255vh;
  }
  .home_nfthero_text {
    display: None;
    position: absolute;
    left: 10%;
    top: 28%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    display: None;
    position: absolute;
    left: 55%;
    top: 7%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_nfthero_mobile {
    position: absolute;
    left: 3%;
    top: 7%;
  }
  .home_nfthero_mobile > img {
    width: 96%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 140.5vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 346vh;
  }
  .home_tarotoken_blur {
    display: None;
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    display: None;
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    display: None;
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }
  .home_tarotoken_mobile {
    position: absolute;
    left: 3%;
    top: 6%;
  }
  .home_tarotoken_mobile > img {
    width: 96%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 128vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 485vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 20%;
    top: 4%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 75%;
  }
  .home_roadmap_table {
    display: None;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    display: None;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .home_roadmap_table_mobile {
    position: absolute;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 0%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table_mobile > img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .home_roadmap_table_one {
    height: 100% !important;
    width: 100% !important;
  }
  .home_roadmap_line {
    display: None;
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.96;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 55vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 611vh;
  }
  .home_partner_text {
    display: None;
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    display: None;
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    display: None;
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    display: None;
    width: 50% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 20%;
  }
  .home_partner_mobile {
    position: absolute;
    left: 1%;
    top: 5%;
  }
  .home_partner_mobile > img {
    width: 100%;
    height: 100%;
  }
  .home_bg {
    background-color: black;
    opacity: 1;
    width: 100vw;
    height: 9vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 664vh;
  }
  .home_footer {
    background-image: url("./Images/herobackground_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 23vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 672.8vh;
  }
  .home_footer_hero {
    display: None;
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 20%;
    top: 15%;
  }
  .home_footer_logo > img {
    width: 73%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 46%;

    left: 17%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 4vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 46%;
    gap: 25%;
    top: 46%;
  }
  .home_footer_social_media_logo > a > img {
    width: 5vw;
    height: 5.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 61%;

    left: 7%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 3.3vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 75%;
    left: 12%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.5vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (min-device-height: 800px) and (min-height: 800px) and (min-device-width: 350px) and (max-device-width: 379px) and (min-width: 350px) and (max-width: 379px) {
  html,
  body {
    overflow-x: hidden;
  }

  body {
    overflow-y: scroll;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 5%;
    top: 11.5%;
  }
  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 26%;
    top: 20%;
  }
  .home_banner_angel > video {
    width: 100%;
    height: 100%;
  }

  /* .home_banner_explore {
    position: absolute;

    left: 22.5%;
    bottom: 10%;
    z-index: 4;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 30vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 70vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 98vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 12%;
    left: 20%;
  }
  .home_taro_heading > img {
    width: 75%;
  }
  .home_taro_para {
    display: None;
    position: absolute;
    top: 23%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 200%;
  }
  .home_taro_para_mobile {
    position: absolute;
    top: 23%;
    left: 3%;
  }
  .home_taro_para_mobile > img {
    width: 96%;
  }
  .home_taro_banner {
    position: absolute;
    top: 60%;
    left: 10%;
  }
  .home_taro_banner > img {
    width: 90%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 83vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 167.5vh;
  }

  .home_nft_chest {
    display: None;
    position: absolute;
    left: 12%;
    top: 15%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }
  .home_nft_chest_mobile {
    position: absolute;
    left: 3%;
    top: 10%;
  }
  .home_nft_chest_mobile > img {
    width: 96%;
    height: 40%;
  }
  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 90vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 249vh;
  }
  .home_nfthero_text {
    display: None;
    position: absolute;
    left: 10%;
    top: 28%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    display: None;
    position: absolute;
    left: 55%;
    top: 7%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_nfthero_mobile {
    position: absolute;
    left: 3%;
    top: 7%;
  }
  .home_nfthero_mobile > img {
    width: 96%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 135vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 338vh;
  }
  .home_tarotoken_blur {
    display: None;
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    display: None;
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    display: None;
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }
  .home_tarotoken_mobile {
    position: absolute;
    left: 3%;
    top: 6%;
  }
  .home_tarotoken_mobile > img {
    width: 96%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 125vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 472vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 20%;
    top: 4%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 75%;
  }
  .home_roadmap_table {
    display: None;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    display: None;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .home_roadmap_table_mobile {
    position: absolute;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 0%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table_mobile > img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .home_roadmap_table_one {
    height: 100% !important;
    width: 100% !important;
  }
  .home_roadmap_line {
    display: None;
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.96;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 45vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 597vh;
  }
  .home_partner_text {
    display: None;
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    display: None;
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    display: None;
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    display: None;
    width: 50% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 20%;
  }
  .home_partner_mobile {
    position: absolute;
    left: 1%;
    top: 5%;
  }
  .home_partner_mobile > img {
    width: 100%;
    height: 100%;
  }
  .home_bg {
    background-color: black;
    opacity: 1;
    width: 100vw;
    height: 9vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 638vh;
  }
  .home_footer {
    background-image: url("./Images/herobackground_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 23vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 646.8vh;
  }
  .home_footer_hero {
    display: None;
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 20%;
    top: 15%;
  }
  .home_footer_logo > img {
    width: 73%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 46%;

    left: 17%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 4vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 46%;
    gap: 25%;
    top: 46%;
  }
  .home_footer_social_media_logo > a > img {
    width: 5vw;
    height: 5.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 61%;

    left: 7%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 3.3vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 75%;
    left: 12%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.5vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (min-device-height: 600px) and (min-height: 600px) and (max-device-height: 700px) and (max-height: 700px) and (min-device-width: 350px) and (max-device-width: 379px) and (min-width: 350px) and (max-width: 379px) {
  html,
  body {
    overflow-x: hidden;
  }

  body {
    overflow-y: scroll;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 5%;
    top: 11.5%;
  }
  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 26%;
    top: 20%;
  }
  .home_banner_angel > video {
    width: 100%;
    height: 100%;
  }

  /* .home_banner_explore {
    position: absolute;

    left: 22.5%;
    bottom: 10%;
    z-index: 4;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 30vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 120vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 98vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 7%;
    left: 20%;
  }
  .home_taro_heading > img {
    width: 75%;
  }
  .home_taro_para {
    display: None;
    position: absolute;
    top: 23%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 200%;
  }
  .home_taro_para_mobile {
    position: absolute;
    top: 20%;
    left: 3%;
  }
  .home_taro_para_mobile > img {
    width: 96%;
  }
  .home_taro_banner {
    position: absolute;
    top: 50%;
    left: 10%;
  }
  .home_taro_banner > img {
    width: 90%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 195.5vh;
  }

  .home_nft_chest {
    display: None;
    position: absolute;
    left: 12%;
    top: 15%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }
  .home_nft_chest_mobile {
    position: absolute;
    left: 3%;
    top: 10%;
  }
  .home_nft_chest_mobile > img {
    width: 96%;
    height: 40%;
  }
  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 120vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 295vh;
  }
  .home_nfthero_text {
    display: None;
    position: absolute;
    left: 10%;
    top: 30%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    display: None;
    position: absolute;
    left: 55%;
    top: 7%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_nfthero_mobile {
    position: absolute;
    left: 3%;
    top: 9%;
  }
  .home_nfthero_mobile > img {
    width: 96%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 180vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 410vh;
  }
  .home_tarotoken_blur {
    display: None;
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    display: None;
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    display: None;
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }
  .home_tarotoken_mobile {
    position: absolute;
    left: 3%;
    top: 6%;
  }
  .home_tarotoken_mobile > img {
    width: 96%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 155vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 575vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 20%;
    top: 4%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 75%;
  }
  .home_roadmap_table {
    display: None;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    display: None;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .home_roadmap_table_mobile {
    position: absolute;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 0%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table_mobile > img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .home_roadmap_table_one {
    height: 100% !important;
    width: 100% !important;
  }
  .home_roadmap_line {
    display: None;
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.96;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 60vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 725vh;
  }
  .home_partner_text {
    display: None;
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    display: None;
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    display: None;
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    display: None;
    width: 50% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 20%;
  }
  .home_partner_mobile {
    position: absolute;
    left: 1%;
    top: 5%;
  }
  .home_partner_mobile > img {
    width: 100%;
    height: 100%;
  }
  .home_bg {
    background-color: black;
    opacity: 1;
    width: 100vw;
    height: 9vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 784.5vh;
  }
  .home_footer {
    background-image: url("./Images/herobackground_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 28vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 793vh;
  }
  .home_footer_hero {
    display: None;
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 20%;
    top: 15%;
  }
  .home_footer_logo > img {
    width: 73%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 46%;

    left: 17%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 4vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 46%;
    gap: 25%;
    top: 46%;
  }
  .home_footer_social_media_logo > a > img {
    width: 5vw;
    height: 5.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 61%;

    left: 7%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 3.3vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 75%;
    left: 12%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.5vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (min-device-height: 700px) and (min-height: 700px) and (max-device-height: 799px) and (max-height: 799px) and (min-device-width: 300px) and (max-device-width: 380px) and (min-width: 300px) and (max-width: 380px) {
  html,
  body {
    overflow-x: hidden;
  }

  body {
    overflow-y: scroll;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 5%;
    top: 11.5%;
  }
  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 26%;
    top: 20%;
  }
  .home_banner_angel > video {
    width: 100%;
    height: 100%;
  }

  /* .home_banner_explore {
    position: absolute;

    left: 22.5%;
    bottom: 10%;
    z-index: 4;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 30vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 96vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 7%;
    left: 20%;
  }
  .home_taro_heading > img {
    width: 75%;
  }
  .home_taro_para {
    display: None;
    position: absolute;
    top: 23%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 200%;
  }
  .home_taro_para_mobile {
    position: absolute;
    top: 17%;
    left: 3%;
  }
  .home_taro_para_mobile > img {
    width: 96%;
  }
  .home_taro_banner {
    position: absolute;
    top: 48%;
    left: 10%;
  }
  .home_taro_banner > img {
    width: 90%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 175.5vh;
  }

  .home_nft_chest {
    display: None;
    position: absolute;
    left: 12%;
    top: 15%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }
  .home_nft_chest_mobile {
    position: absolute;
    left: 3%;
    top: 10%;
  }
  .home_nft_chest_mobile > img {
    width: 96%;
    height: 40%;
  }
  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 120vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 262vh;
  }
  .home_nfthero_text {
    display: None;
    position: absolute;
    left: 10%;
    top: 30%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    display: None;
    position: absolute;
    left: 55%;
    top: 7%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_nfthero_mobile {
    position: absolute;
    left: 3%;
    top: 6%;
  }
  .home_nfthero_mobile > img {
    width: 96%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 180vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 356vh;
  }
  .home_tarotoken_blur {
    display: None;
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    display: None;
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    display: None;
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }
  .home_tarotoken_mobile {
    position: absolute;
    left: 3%;
    top: 5%;
  }
  .home_tarotoken_mobile > img {
    width: 96%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 130vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 496vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 20%;
    top: 4%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 75%;
  }
  .home_roadmap_table {
    display: None;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    display: None;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .home_roadmap_table_mobile {
    position: absolute;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 0%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table_mobile > img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .home_roadmap_table_one {
    height: 100% !important;
    width: 100% !important;
  }
  .home_roadmap_line {
    display: None;
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.96;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 60vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 624vh;
  }
  .home_partner_text {
    display: None;
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    display: None;
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    display: None;
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    display: None;
    width: 50% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 20%;
  }
  .home_partner_mobile {
    position: absolute;
    left: 1%;
    top: 5%;
  }
  .home_partner_mobile > img {
    width: 100%;
    height: 100%;
  }
  .home_bg {
    background-color: black;
    opacity: 1;
    width: 100vw;
    height: 7vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 680vh;
    /* display: None; */
  }
  .home_footer {
    background-image: url("./Images/herobackground_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 24vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 686.8vh;
  }
  .home_footer_hero {
    display: None;
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 20%;
    top: 15%;
  }
  .home_footer_logo > img {
    width: 73%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 46%;

    left: 17%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 4vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 46%;
    gap: 25%;
    top: 46%;
  }
  .home_footer_social_media_logo > a > img {
    width: 5vw;
    height: 5.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 61%;

    left: 7%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 3.3vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 75%;
    left: 12%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.5vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (min-device-height: 895px) and (min-height: 895px) and (max-device-height: 928px) and (max-height: 928px) and (min-device-width: 400px) and (max-device-width: 430px) and (min-width: 400px) and (max-width: 430px) {
  html,
  body {
    overflow-x: hidden;
  }

  body {
    overflow-y: scroll;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 5%;
    top: 11.5%;
  }
  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 26%;
    top: 20%;
  }
  .home_banner_angel > video {
    width: 100%;
    height: 100%;
  }

  /* .home_banner_explore {
    position: absolute;

    left: 24.5%;
    bottom: 10%;
    z-index: 4;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 30vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 96vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 7%;
    left: 20%;
  }
  .home_taro_heading > img {
    width: 75%;
  }
  .home_taro_para {
    display: None;
    position: absolute;
    top: 23%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 200%;
  }
  .home_taro_para_mobile {
    position: absolute;
    top: 17%;
    left: 3%;
  }
  .home_taro_para_mobile > img {
    width: 96%;
  }
  .home_taro_banner {
    position: absolute;
    top: 48%;
    left: 10%;
  }
  .home_taro_banner > img {
    width: 90%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 175.5vh;
  }

  .home_nft_chest {
    display: None;
    position: absolute;
    left: 12%;
    top: 15%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }
  .home_nft_chest_mobile {
    position: absolute;
    left: 3%;
    top: 10%;
  }
  .home_nft_chest_mobile > img {
    width: 96%;
    height: 40%;
  }
  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 120vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 262vh;
  }
  .home_nfthero_text {
    display: None;
    position: absolute;
    left: 10%;
    top: 30%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    display: None;
    position: absolute;
    left: 55%;
    top: 7%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_nfthero_mobile {
    position: absolute;
    left: 3%;
    top: 6%;
  }
  .home_nfthero_mobile > img {
    width: 96%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 180vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 356vh;
  }
  .home_tarotoken_blur {
    display: None;
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    display: None;
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    display: None;
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }
  .home_tarotoken_mobile {
    position: absolute;
    left: 3%;
    top: 5%;
  }
  .home_tarotoken_mobile > img {
    width: 96%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 124.5vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 496vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 20%;
    top: 4%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 75%;
  }
  .home_roadmap_table {
    display: None;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    display: None;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .home_roadmap_table_mobile {
    position: absolute;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 0%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table_mobile > img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .home_roadmap_table_one {
    height: 100% !important;
    width: 100% !important;
  }
  .home_roadmap_line {
    display: None;
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.96;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 52vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 620vh;
  }
  .home_partner_text {
    display: None;
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    display: None;
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    display: None;
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    display: None;
    width: 50% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 20%;
  }
  .home_partner_mobile {
    position: absolute;
    left: 1%;
    top: 5%;
  }
  .home_partner_mobile > img {
    width: 100%;
    height: 100%;
  }
  .home_bg {
    background-color: black;
    opacity: 1;
    width: 100vw;
    height: 7vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 782vh;
    display: None;
  }
  .home_footer {
    background-image: url("./Images/herobackground_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 23vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 671.8vh;
  }
  .home_footer_hero {
    display: None;
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 20%;
    top: 15%;
  }
  .home_footer_logo > img {
    width: 73%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 46%;

    left: 17%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 4vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 46%;
    gap: 25%;
    top: 46%;
  }
  .home_footer_social_media_logo > a > img {
    width: 5vw;
    height: 5.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 61%;

    left: 7%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 3.3vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 75%;
    left: 12%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.5vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (min-device-height: 620px) and (min-height: 620px) and (max-device-height: 660px) and (max-height: 660px) and (min-device-width: 270px) and (max-device-width: 300px) and (min-width: 270px) and (max-width: 300px) {
  html,
  body {
    overflow-x: hidden;
  }

  body {
    overflow-y: scroll;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 5%;
    top: 11.5%;
  }
  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 26%;
    top: 20%;
  }
  .home_banner_angel > video {
    width: 100%;
    height: 100%;
  }

  /* .home_banner_explore {
    position: absolute;

    left: 14.5%;
    bottom: 10%;
    z-index: 4;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 30vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 96vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 7%;
    left: 20%;
  }
  .home_taro_heading > img {
    width: 75%;
  }
  .home_taro_para {
    display: None;
    position: absolute;
    top: 23%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 200%;
  }
  .home_taro_para_mobile {
    position: absolute;
    top: 17%;
    left: 3%;
  }
  .home_taro_para_mobile > img {
    width: 96%;
  }
  .home_taro_banner {
    position: absolute;
    top: 48%;
    left: 10%;
  }
  .home_taro_banner > img {
    width: 90%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 172.5vh;
  }

  .home_nft_chest {
    display: None;
    position: absolute;
    left: 12%;
    top: 15%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }
  .home_nft_chest_mobile {
    position: absolute;
    left: 3%;
    top: 10%;
  }
  .home_nft_chest_mobile > img {
    width: 96%;
    height: 40%;
  }
  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 120vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 254vh;
  }
  .home_nfthero_text {
    display: None;
    position: absolute;
    left: 10%;
    top: 30%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    display: None;
    position: absolute;
    left: 55%;
    top: 7%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_nfthero_mobile {
    position: absolute;
    left: 3%;
    top: 6%;
  }
  .home_nfthero_mobile > img {
    width: 96%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 180vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 340vh;
  }
  .home_tarotoken_blur {
    display: None;
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    display: None;
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    display: None;
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }
  .home_tarotoken_mobile {
    position: absolute;
    left: 3%;
    top: 5%;
  }
  .home_tarotoken_mobile > img {
    width: 96%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 112vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 470vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 20%;
    top: 4%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 75%;
  }
  .home_roadmap_table {
    display: None;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    display: None;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .home_roadmap_table_mobile {
    position: absolute;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 0%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table_mobile > img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .home_roadmap_table_one {
    height: 100% !important;
    width: 100% !important;
  }
  .home_roadmap_line {
    display: None;
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.96;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 50vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 580vh;
  }
  .home_partner_text {
    display: None;
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    display: None;
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    display: None;
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    display: None;
    width: 50% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 20%;
  }
  .home_partner_mobile {
    position: absolute;
    left: 1%;
    top: 5%;
  }
  .home_partner_mobile > img {
    width: 100%;
    height: 100%;
  }
  .home_bg {
    background-color: black;
    opacity: 1;
    width: 100vw;
    height: 7vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 782vh;
    display: None;
  }
  .home_footer {
    background-image: url("./Images/herobackground_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 23vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 629.5vh;
  }
  .home_footer_hero {
    display: None;
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 20%;
    top: 15%;
  }
  .home_footer_logo > img {
    width: 73%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 46%;

    left: 17%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 4vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 46%;
    gap: 25%;
    top: 46%;
  }
  .home_footer_social_media_logo > a > img {
    width: 5vw;
    height: 5.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 61%;

    left: 7%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 3.3vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 75%;
    left: 12%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.5vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (min-device-height: 860px) and (min-height: 860px) and (max-device-height: 900px) and (max-height: 900px) and (min-device-width: 400px) and (max-device-width: 430px) and (min-width: 400px) and (max-width: 430px) {
  html,
  body {
    overflow-x: hidden;
  }

  body {
    overflow-y: scroll;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 5%;
    top: 11.5%;
  }
  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 26%;
    top: 20%;
  }
  .home_banner_angel > video {
    width: 100%;
    height: 100%;
  }

  /* .home_banner_explore {
    position: absolute;

    left: 24.5%;
    bottom: 10%;
    z-index: 4;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 30vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 96vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 7%;
    left: 20%;
  }
  .home_taro_heading > img {
    width: 75%;
  }
  .home_taro_para {
    display: None;
    position: absolute;
    top: 23%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 200%;
  }
  .home_taro_para_mobile {
    position: absolute;
    top: 17%;
    left: 3%;
  }
  .home_taro_para_mobile > img {
    width: 96%;
  }
  .home_taro_banner {
    position: absolute;
    top: 48%;
    left: 10%;
  }
  .home_taro_banner > img {
    width: 90%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 175.5vh;
  }

  .home_nft_chest {
    display: None;
    position: absolute;
    left: 12%;
    top: 15%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }
  .home_nft_chest_mobile {
    position: absolute;
    left: 3%;
    top: 10%;
  }
  .home_nft_chest_mobile > img {
    width: 96%;
    height: 40%;
  }
  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 120vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 262vh;
  }
  .home_nfthero_text {
    display: None;
    position: absolute;
    left: 10%;
    top: 30%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    display: None;
    position: absolute;
    left: 55%;
    top: 7%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_nfthero_mobile {
    position: absolute;
    left: 3%;
    top: 6%;
  }
  .home_nfthero_mobile > img {
    width: 96%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 180vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 356vh;
  }
  .home_tarotoken_blur {
    display: None;
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    display: None;
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    display: None;
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }
  .home_tarotoken_mobile {
    position: absolute;
    left: 3%;
    top: 5%;
  }
  .home_tarotoken_mobile > img {
    width: 96%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 124.5vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 496vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 20%;
    top: 4%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 75%;
  }
  .home_roadmap_table {
    display: None;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    display: None;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .home_roadmap_table_mobile {
    position: absolute;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 0%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table_mobile > img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .home_roadmap_table_one {
    height: 100% !important;
    width: 100% !important;
  }
  .home_roadmap_line {
    display: None;
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.96;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 52vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 620vh;
  }
  .home_partner_text {
    display: None;
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    display: None;
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    display: None;
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    display: None;
    width: 50% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 20%;
  }
  .home_partner_mobile {
    position: absolute;
    left: 1%;
    top: 5%;
  }
  .home_partner_mobile > img {
    width: 100%;
    height: 100%;
  }
  .home_bg {
    background-color: black;
    opacity: 1;
    width: 100vw;
    height: 7vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 782vh;
    display: None;
  }
  .home_footer {
    background-image: url("./Images/herobackground_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 23vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 671.8vh;
  }
  .home_footer_hero {
    display: None;
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 20%;
    top: 15%;
  }
  .home_footer_logo > img {
    width: 73%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 46%;

    left: 17%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 4vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 46%;
    gap: 25%;
    top: 46%;
  }
  .home_footer_social_media_logo > a > img {
    width: 5vw;
    height: 5.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 61%;

    left: 7%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 3.3vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 75%;
    left: 12%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.5vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (min-device-height: 700px) and (min-height: 700px) and (max-device-height: 750px) and (max-height: 750px) and (min-device-width: 400px) and (max-device-width: 430px) and (min-width: 400px) and (max-width: 430px) {
  html,
  body {
    overflow-x: hidden;
  }

  body {
    overflow-y: scroll;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 5%;
    top: 11.5%;
  }
  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 26%;
    top: 20%;
  }
  .home_banner_angel > video {
    width: 100%;
    height: 100%;
  }

  /* .home_banner_explore {
    position: absolute;

    left: 24.5%;
    bottom: 10%;
    z-index: 4;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 30vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 98vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 7%;
    left: 20%;
  }
  .home_taro_heading > img {
    width: 75%;
  }
  .home_taro_para {
    display: None;
    position: absolute;
    top: 24%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 200%;
  }
  .home_taro_para_mobile {
    position: absolute;
    top: 18%;
    left: 3%;
  }
  .home_taro_para_mobile > img {
    width: 96%;
  }
  .home_taro_banner {
    position: absolute;
    top: 50%;
    left: 10%;
  }
  .home_taro_banner > img {
    width: 90%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 185.5vh;
  }

  .home_nft_chest {
    display: None;
    position: absolute;
    left: 12%;
    top: 15%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }
  .home_nft_chest_mobile {
    position: absolute;
    left: 3%;
    top: 10%;
  }
  .home_nft_chest_mobile > img {
    width: 96%;
    height: 40%;
  }
  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 120vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 282vh;
  }
  .home_nfthero_text {
    display: None;
    position: absolute;
    left: 10%;
    top: 30%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    display: None;
    position: absolute;
    left: 55%;
    top: 7%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_nfthero_mobile {
    position: absolute;
    left: 3%;
    top: 6%;
  }
  .home_nfthero_mobile > img {
    width: 96%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 180vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 390vh;
  }
  .home_tarotoken_blur {
    display: None;
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    display: None;
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    display: None;
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }
  .home_tarotoken_mobile {
    position: absolute;
    left: 3%;
    top: 5%;
  }
  .home_tarotoken_mobile > img {
    width: 96%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 147.5vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 560vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 20%;
    top: 4%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 75%;
  }
  .home_roadmap_table {
    display: None;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    display: None;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .home_roadmap_table_mobile {
    position: absolute;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 0%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table_mobile > img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .home_roadmap_table_one {
    height: 100% !important;
    width: 100% !important;
  }
  .home_roadmap_line {
    display: None;
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.96;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 65.82vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 707vh;
  }
  .home_partner_text {
    display: None;
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    display: None;
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    display: None;
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    display: None;
    width: 50% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 20%;
  }
  .home_partner_mobile {
    position: absolute;
    left: 1%;
    top: 5%;
  }
  .home_partner_mobile > img {
    width: 100%;
    height: 100%;
  }
  .home_bg {
    background-color: black;
    opacity: 1;
    width: 100vw;
    height: 7vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 782vh;
    display: None;
  }
  .home_footer {
    background-image: url("./Images/herobackground_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 28vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 772.8vh;
  }
  .home_footer_hero {
    display: None;
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 20%;
    top: 15%;
  }
  .home_footer_logo > img {
    width: 73%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 46%;

    left: 17%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 4vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 46%;
    gap: 25%;
    top: 46%;
  }
  .home_footer_social_media_logo > a > img {
    width: 5vw;
    height: 5.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 61%;

    left: 7%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 3.3vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 75%;
    left: 12%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.5vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (min-device-height: 1000px) and (min-height: 1000px) and (max-device-height: 1400px) and (max-height: 1400px) and (min-device-width: 750px) and (max-device-width: 1030px) and (min-width: 750px) and (max-width: 1030px) {
  html,
  body {
    overflow-x: hidden;
  }
  body {
    overflow-y: scroll;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 5%;
    top: 11.5%;
  }
  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 28%;
    top: 5%;
  }
  .home_banner_angel > video {
    width: 100%;
    height: 100%;
  }

  /* .home_banner_explore {
    position: absolute;

    left: 28.5%;
    bottom: 10%;
    z-index: 4;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 30vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_banner_explore > img {
    width: 30vh;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 120vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 99.8vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 7%;
    left: 20%;
  }
  .home_taro_heading > img {
    width: 75%;
  }
  .home_taro_para {
    display: None;
    position: absolute;
    top: 24%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 200%;
  }
  .home_taro_para_mobile {
    position: absolute;
    top: 20%;
    left: 3%;
  }
  .home_taro_para_mobile > img {
    width: 96%;
  }
  .home_taro_banner {
    position: absolute;
    top: 55%;
    left: 10%;
  }
  .home_taro_banner > img {
    width: 90%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 130vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 210.5vh;
  }

  .home_nft_chest {
    display: None;
    position: absolute;
    left: 12%;
    top: 15%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }
  .home_nft_chest_mobile {
    position: absolute;
    left: 3%;
    top: 10%;
  }
  .home_nft_chest_mobile > img {
    width: 96%;
    height: 40%;
  }
  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 150vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 340vh;
  }
  .home_nfthero_text {
    display: None;
    position: absolute;
    left: 10%;
    top: 30%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    display: None;
    position: absolute;
    left: 55%;
    top: 7%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_nfthero_mobile {
    position: absolute;
    left: 3%;
    top: 6%;
  }
  .home_nfthero_mobile > img {
    width: 96%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 220vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 480vh;
  }
  .home_tarotoken_blur {
    display: None;
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    display: None;
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    display: None;
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }
  .home_tarotoken_mobile {
    position: absolute;
    left: 3%;
    top: 5%;
  }
  .home_tarotoken_mobile > img {
    width: 96%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 200.5vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 699vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 20%;
    top: 4%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 75%;
  }
  .home_roadmap_table {
    display: None;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    display: None;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .home_roadmap_table_mobile {
    position: absolute;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 0%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table_mobile > img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .home_roadmap_table_one {
    height: 100% !important;
    width: 100% !important;
  }
  .home_roadmap_line {
    display: None;
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.96;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 82vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 899vh;
  }
  .home_partner_text {
    display: None;
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    display: None;
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    display: None;
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    display: None;
    width: 50% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 20%;
  }
  .home_partner_mobile {
    position: absolute;
    left: 1%;
    top: 5%;
  }
  .home_partner_mobile > img {
    width: 100%;
    height: 100%;
  }
  .home_bg {
    background-color: black;
    opacity: 1;
    width: 100vw;
    height: 10vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 971vh;
    display: None;
  }
  .home_footer {
    background-image: url("./Images/herobackground_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 35vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 980.8vh;
  }
  .home_footer_hero {
    display: None;
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 20%;
    top: 15%;
  }
  .home_footer_logo > img {
    width: 73%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 46%;

    left: 17%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 4vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 46%;
    gap: 25%;
    top: 46%;
  }
  .home_footer_social_media_logo > a > img {
    width: 5vw;
    height: 5.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 61%;

    left: 7%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 3.3vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 75%;
    left: 12%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.5vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (min-device-height: 700px) and (min-height: 700px) and (max-device-height: 750px) and (max-height: 750px) and (min-device-width: 500px) and (max-device-width: 550px) and (min-width: 500px) and (max-width: 550px) {
  html,
  body {
    overflow-x: hidden;
  }
  .logo {
    position: absolute;
    width: 187.21px;
    height: 46px;
    left: 32%;
    top: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 13.6292px;
  }

  body {
    overflow-y: scroll;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 5%;
    top: 11.5%;
  }
  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 26%;
    top: 20%;
  }
  .home_banner_angel > video {
    width: 100%;
    height: 100%;
  }

  /* .home_banner_explore {
    position: absolute;

    left: 30.5%;
    bottom: 10%;
    z-index: 4;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 30vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 120vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 7%;
    left: 20%;
  }
  .home_taro_heading > img {
    width: 75%;
  }
  .home_taro_para {
    display: None;
    position: absolute;
    top: 30%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 200%;
  }
  .home_taro_para_mobile {
    position: absolute;
    top: 22%;
    left: 3%;
  }
  .home_taro_para_mobile > img {
    width: 96%;
  }
  .home_taro_banner {
    position: absolute;
    top: 55%;
    left: 10%;
  }
  .home_taro_banner > img {
    width: 90%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 150vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 210.5vh;
  }

  .home_nft_chest {
    display: None;
    position: absolute;
    left: 12%;
    top: 15%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }
  .home_nft_chest_mobile {
    position: absolute;
    left: 3%;
    top: 10%;
  }
  .home_nft_chest_mobile > img {
    width: 96%;
    height: 40%;
  }
  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 180vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 350vh;
  }
  .home_nfthero_text {
    display: None;
    position: absolute;
    left: 10%;
    top: 35%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    display: None;
    position: absolute;
    left: 55%;
    top: 10%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_nfthero_mobile {
    position: absolute;
    left: 3%;
    top: 8%;
  }
  .home_nfthero_mobile > img {
    width: 96%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 260vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 500vh;
  }
  .home_tarotoken_blur {
    display: None;
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    display: None;
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    display: None;
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }
  .home_tarotoken_mobile {
    position: absolute;
    left: 3%;
    top: 5%;
  }
  .home_tarotoken_mobile > img {
    width: 96%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 200.5vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 720vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 20%;
    top: 4%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 75%;
  }
  .home_roadmap_table {
    display: None;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    display: None;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .home_roadmap_table_mobile {
    position: absolute;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 0%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table_mobile > img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .home_roadmap_table_one {
    height: 100% !important;
    width: 100% !important;
  }
  .home_roadmap_line {
    display: None;
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.96;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 85vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 915vh;
  }
  .home_partner_text {
    display: None;
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    display: None;
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    display: None;
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    display: None;
    width: 50% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 20%;
  }
  .home_partner_mobile {
    position: absolute;
    left: 1%;
    top: 5%;
  }
  .home_partner_mobile > img {
    width: 100%;
    height: 100%;
  }
  .home_bg {
    background-color: black;
    opacity: 1;
    width: 100vw;
    height: 7vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 782vh;
    display: None;
  }
  .home_footer {
    background-image: url("./Images/herobackground_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 33vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 999.8vh;
  }
  .home_footer_hero {
    display: None;
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 20%;
    top: 15%;
  }
  .home_footer_logo > img {
    width: 73%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 46%;

    left: 17%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 4vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 46%;
    gap: 25%;
    top: 46%;
  }
  .home_footer_social_media_logo > a > img {
    width: 5vw;
    height: 5.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 61%;

    left: 7%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 3.3vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 75%;
    left: 12%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.5vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (min-device-height: 550px) and (min-height: 550px) and (max-device-height: 650px) and (max-height: 650px) and (min-device-width: 950px) and (max-device-width: 1050px) and (min-width: 950px) and (max-width: 1050px) {
  html,
  body {
    overflow-x: hidden;
  }

  body {
    overflow-y: scroll;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 5%;
    top: 11.5%;
  }
  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 40%;
    top: 8%;
  }
  .home_banner_angel > video {
    width: 50%;
    height: 50%;
  }

  /* .home_banner_explore {
    position: absolute;

    left: 37.5%;
    bottom: 10%;
    z-index: 4;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 30vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_banner_explore > img {
    width: 40vh;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 300vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 84.8vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 10%;
    left: 20%;
  }
  .home_taro_heading > img {
    width: 75%;
  }
  .home_taro_para {
    display: None;
    position: absolute;
    top: 30%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 200%;
  }
  .home_taro_para_mobile {
    position: absolute;
    top: 20%;
    left: 3%;
  }
  .home_taro_para_mobile > img {
    width: 96%;
  }
  .home_taro_banner {
    position: absolute;
    top: 50%;
    left: 10%;
  }
  .home_taro_banner > img {
    width: 90%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 330vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 330.5vh;
  }

  .home_nft_chest {
    display: None;
    position: absolute;
    left: 12%;
    top: 10%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }
  .home_nft_chest_mobile {
    position: absolute;
    left: 3%;
    top: 8%;
  }
  .home_nft_chest_mobile > img {
    width: 96%;
    height: 40%;
  }
  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 350vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 620vh;
  }
  .home_nfthero_text {
    display: None;
    position: absolute;
    left: 10%;
    top: 30%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    display: None;
    position: absolute;
    left: 55%;
    top: 7%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_nfthero_mobile {
    position: absolute;
    left: 3%;
    top: 6%;
  }
  .home_nfthero_mobile > img {
    width: 96%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 500vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 940vh;
  }
  .home_tarotoken_blur {
    display: None;
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    display: None;
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    display: None;
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }
  .home_tarotoken_mobile {
    position: absolute;
    left: 3%;
    top: 5%;
  }
  .home_tarotoken_mobile > img {
    width: 96%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 460.5vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 1440vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 20%;
    top: 4%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 75%;
  }
  .home_roadmap_table {
    display: None;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    display: None;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .home_roadmap_table_mobile {
    position: absolute;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 0%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table_mobile > img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .home_roadmap_table_one {
    height: 100% !important;
    width: 100% !important;
  }
  .home_roadmap_line {
    display: None;
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.96;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 200vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 1890vh;
  }
  .home_partner_text {
    display: None;
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    display: None;
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    display: None;
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    display: None;
    width: 50% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 20%;
  }
  .home_partner_mobile {
    position: absolute;
    left: 1%;
    top: 5%;
  }
  .home_partner_mobile > img {
    width: 100%;
    height: 100%;
  }
  .home_bg {
    background-color: black;
    opacity: 1;
    width: 100vw;
    height: 7vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 782vh;
    display: None;
  }
  .home_footer {
    background-image: url("./Images/herobackground_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 84vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 2089.5vh;
  }
  .home_footer_hero {
    display: None;
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 20%;
    top: 15%;
  }
  .home_footer_logo > img {
    width: 73%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 46%;

    left: 17%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 4vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 46%;
    gap: 25%;
    top: 46%;
  }
  .home_footer_social_media_logo > a > img {
    width: 5vw;
    height: 5.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 61%;

    left: 7%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 3.3vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 75%;
    left: 12%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.5vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (min-device-height: 480px) and (min-height: 480px) and (max-device-height: 530px) and (max-height: 530px) and (min-device-width: 680px) and (max-device-width: 730px) and (min-width: 680px) and (max-width: 730px) {
  html,
  body {
    overflow-x: hidden;
  }
  .logo {
    position: absolute;
    width: 187.21px;
    height: 46px;
    left: 34.5%;
    top: 10%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 13.6292px;
  }
  body {
    overflow-y: scroll;
  }
  .home_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .home_banner_map {
    position: absolute;
    left: 5%;
    top: 11.5%;
  }
  .home_banner_map > img,
  .home_banner_map > video {
    height: 87vh;
    width: 88vw;
  }

  #home-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .home_banner_angel {
    z-index: 2;
    position: absolute;
    left: 35%;
    top: 5%;
  }
  .home_banner_angel > video {
    width: 60%;
    height: 60%;
  }

  /* .home_banner_explore {
    position: absolute;

    left: 35.5%;
    bottom: 10%;
    z-index: 4;
  } */
  .home_banner_explore > img {
    position: absolute;
    width: 40vh;
    bottom: 10%;
    z-index: 4;
  }
  .home_taro {
    background-image: url(./Images/bgtaroverse.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 202vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 99.8vh;
  }
  .home_taro_heading {
    position: absolute;
    top: 7%;
    left: 20%;
  }
  .home_taro_heading > img {
    width: 75%;
  }
  .home_taro_para {
    display: None;
    position: absolute;
    top: 24%;
    left: 8%;
  }
  .home_taro_para > img {
    width: 200%;
  }
  .home_taro_para_mobile {
    position: absolute;
    top: 20%;
    left: 3%;
  }
  .home_taro_para_mobile > img {
    width: 96%;
  }
  .home_taro_banner {
    position: absolute;
    top: 60%;
    left: 10%;
  }
  .home_taro_banner > img {
    width: 90%;
    height: 65%;
  }

  .home_nft {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 250vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 300.5vh;
  }

  .home_nft_chest {
    display: None;
    position: absolute;
    left: 12%;
    top: 15%;
  }
  .home_nft_chest > img {
    width: 85%;
    height: 40%;
  }
  .home_nft_chest_mobile {
    position: absolute;
    left: 3%;
    top: 10%;
  }
  .home_nft_chest_mobile > img {
    width: 96%;
    height: 40%;
  }
  .home_nft_blur {
    position: absolute;
    left: 23%;
    top: -15%;
  }
  .home_nft_blur > img {
    width: 60%;
    height: 60%;
  }

  .home_nfthero {
    background-image: url(./Images/bgnfthero.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 270vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 540vh;
  }
  .home_nfthero_text {
    display: None;
    position: absolute;
    left: 10%;
    top: 30%;
  }
  .home_nfthero_text > img {
    width: 45%;
    height: 90%;
  }
  .home_nfthero_image {
    display: None;
    position: absolute;
    left: 55%;
    top: 7%;
  }
  .home_nfthero_image > img {
    width: 80%;
    height: 70%;
  }
  .home_nfthero_mobile {
    position: absolute;
    left: 3%;
    top: 6%;
  }
  .home_nfthero_mobile > img {
    width: 96%;
    height: 70%;
  }
  .home_tarotoken {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 520vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 810vh;
  }
  .home_tarotoken_blur {
    display: None;
    position: absolute;
    left: -8%;
    top: -55%;
  }
  .home_tarotoken_blur > img {
    width: 80%;
    height: 80%;
  }

  .home_tarotoken_para {
    display: None;
    position: absolute;
    left: 8%;
    top: 10%;
  }
  .home_tarotoken_para > img {
    width: 90%;
    height: 100%;
  }

  .home_tarotoken_logo {
    display: None;
    position: absolute;
    left: 20%;
    top: 34%;
  }
  .home_tarotoken_logo > img {
    width: 70%;
    height: 100%;
  }
  .home_tarotoken_mobile {
    position: absolute;
    left: 3%;
    top: 5%;
  }
  .home_tarotoken_mobile > img {
    width: 96%;
    height: 100%;
  }

  .home_roadmap {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 380.5vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 1230vh;
  }
  .home_roadmap_text {
    position: absolute;
    left: 20%;
    top: 4%;
  }
  .home_roadmap_text > img {
    height: 85%;
    width: 75%;
  }
  .home_roadmap_table {
    display: None;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table > img {
    display: None;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .home_roadmap_table_mobile {
    position: absolute;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 0%;
    top: 13%;
    left: 3%;
    right: 3%;
  }
  .home_roadmap_table_mobile > img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .home_roadmap_table_one {
    height: 100% !important;
    width: 100% !important;
  }
  .home_roadmap_line {
    display: None;
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .home_roadmap_line > img {
    width: 86%;
  }

  .home_roadmap_nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 15%;
    gap: 30%;
    width: 5%;
    height: 10%;
  }
  .home_roadmap_nav_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_left > img {
    height: 50%;
    width: 50%;
  }
  .home_roadmap_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;

    position: absolute;
    width: 50%;
    height: 50%;
    right: -30%;

    background: rgba(255, 255, 255, 0.1);
  }
  .home_roadmap_nav_right > img {
    height: 50%;
    width: 50%;
  }

  .home_partner {
    background-color: black;
    opacity: 0.96;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 150vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 1600vh;
  }
  .home_partner_text {
    display: None;
    position: absolute;
    left: 35%;
    top: 8%;
  }
  .home_partner_text > img {
    width: 40%;
    height: 100%;
  }
  .home_partner_investor {
    display: None;
    position: absolute;
    left: 15%;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    column-gap: 15%;
  }
  .home_partner_investor > img {
    display: None;
    width: 100%;
    height: 50%;
  }
  .home_partner_investor_two {
    display: None;
    width: 50% !important;
    height: 100% !important;
    position: absolute;
    top: 130%;
    left: 20%;
  }
  .home_partner_mobile {
    position: absolute;
    left: 1%;
    top: 5%;
  }
  .home_partner_mobile > img {
    width: 100%;
    height: 100%;
  }
  .home_bg {
    background-color: black;
    opacity: 1;
    width: 100vw;
    height: 7vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 782vh;
    display: None;
  }
  .home_footer {
    background-image: url("./Images/herobackground_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 70vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 1750vh;
  }
  .home_footer_hero {
    display: None;
    position: absolute;
    left: 10%;
    top: -20%;
    z-index: 9999;
  }
  .home_footer_hero > img {
    width: 30%;
  }
  .home_footer_logo {
    position: absolute;
    left: 20%;
    top: 15%;
  }
  .home_footer_logo > img {
    width: 73%;
  }
  .home_footer_social_media {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 46%;

    left: 17%;
  }
  .home_footer_social_media > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 4vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_social_media_logo {
    position: absolute;
    display: flex;
    left: 46%;
    gap: 25%;
    top: 46%;
  }
  .home_footer_social_media_logo > a > img {
    width: 5vw;
    height: 5.25vw;
  }

  .home_footer_footnotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 61%;

    left: 7%;
  }
  .home_footer_footnotes > p {
    color: #ffff;
    font-size: 3.3vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_footer_term {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 75%;
    left: 12%;
  }
  .home_footer_term > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.5vw;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .home_partner_blur {
    position: absolute;
    left: -22%;
    top: -49%;
  }
  .home_partner_blur > img {
    width: 80%;
    height: 80%;
  }
}
