textarea:focus,
input:focus {
  outline: none;
}
.register_banner {
  background-image: url(./Images/bgmap.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.register_banner_map {
  position: absolute;
  left: 4%;
  top: 11.5%;
}
.register_banner_map > img,
.register_banner_map > video {
  height: 87vh;
  width: 88vw;
}
.register_angel,
#register_angel {
  position: absolute;

  left: 54%;
  top: -18%;
  z-index: 1;
}
.register_angel > img,
.register_angel > video,
#register_angel > video {
  width: 280px;
  height: 481px;
}
#register-background-video {
  width: 90vw;
  height: 85vh;
  object-fit: cover;
  position: absolute;
  left: 4.5%;
  right: 0;
  top: 10%;
  bottom: 0;
  z-index: 2;
  border-radius: 2%;
}
.register_box {
  position: absolute;
  width: 40%;
  height: 80%;
  left: 27%;
  top: 10%;

  background: rgba(255, 255, 255, 0.03);
  box-shadow: inset 0px 4px 68px rgba(255, 255, 255, 0.09),
    inset 0px 2px 4px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(68px);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 25px;
}
.register_logo {
  position: absolute;
  left: 41%;
  top: 2%;
}
.register_logo > img {
  width: 100%;
}
.register_box_welcome {
  position: absolute;
  left: 24%;
  top: 3%;
}
.register_box_welcome > img {
  height: 30%;
  width: 70%;
}
.register_with_email {
  position: absolute;
  top: 16%;
  left: 10%;
}
.register_username {
  position: absolute;
  top: 28%;
  left: 10%;
}
.register_email {
  position: absolute;
  top: 43%;
  left: 10%;
}
.register_with_email > p,
.register_password > p,
.register_username > p,
.register_email > p {
  width: 100px;
  height: 18px;
  margin-left: 1%;
  top: 0%;

  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;
}

.register_with_email > input,
.register_password > input,
.register_username > input,
.register_email > input {
  width: 29vw;
  height: 35px;
  margin-top: 3%;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  padding-left: 5%;
  border-color: transparent;
  color: #ffffff;
}
::placeholder {
  width: 156px;
  height: 21px;

  margin-left: 1%;

  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;

  color: rgba(255, 255, 255, 0.2);
}

.register_password {
  position: absolute;
  top: 58%;
  left: 10%;
}
.forgot_password {
  position: absolute;
  top: 50%;
  left: 72%;
}
.forgot_password > P {
  width: 100%;
  height: 18px;
  color: rgba(255, 255, 255, 0.5) !important;

  top: 0%;

  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;
}
.register_register {
  position: absolute;
  top: 72%;
  left: 10.5%;
}
.register_register > p {
  width: 250px;
  height: 18px;
  left: 512px;
  top: 531px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  color: rgba(255, 255, 255, 0.5);
}

.register_register > p > span {
  color: #ffffff;
}
.register_button {
  position: absolute;
  top: 75%;
  left: 35%;
}
.register_button > img {
  width: 50%;
}

@media only screen and (min-width: 1px) and (min-device-width: 1px) and (max-width: 400px) and (max-device-width: 400px) {
  textarea:focus,
  input:focus {
    outline: none;
  }
  .register_banner {
    background-image: url(./Images/bgmap.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: hidden;
  }
  .register_banner_map {
    position: absolute;
    left: 4%;
    top: 11.5%;
  }
  .register_banner_map > img,
  .register_banner_map > video {
    height: 87vh;
    width: 88vw;
  }
  .register_angel,
  #register_angel {
    display: none;
    position: absolute;

    left: 53%;
    top: -2%;
  }
  .register_angel > img,
  .register_angel > video,
  #register_angel > video {
    width: 400px;
    height: 501px;
  }
  #register-background-video {
    width: 90vw;
    height: 85vh;
    object-fit: cover;
    position: absolute;
    left: 4.5%;
    right: 0;
    top: 10%;
    bottom: 0;
    z-index: 2;
    border-radius: 2%;
  }
  .register_box {
    position: absolute;
    width: 90%;
    height: 500px;
    left: 5%;
    top: 15%;

    background: rgba(255, 255, 255, 0.03);
    box-shadow: inset 0px 4px 68px rgba(255, 255, 255, 0.09),
      inset 0px 2px 4px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(68px);

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 25px;
  }
  .register_logo {
    position: absolute;
    left: 27%;
    top: 7%;
  }
  .register_logo > img {
    width: 100%;
  }
  .register_box_welcome {
    position: absolute;
    left: 24%;
    top: 5%;
  }
  .register_box_welcome > img {
    height: 30%;
    width: 70%;
  }
  .register_email {
    position: absolute;
    top: 30%;
    left: 10%;
  }
  .register_email > p,
  .register_password > p {
    width: 100px;
    height: 18px;
    margin-left: 1%;
    top: 0%;

    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    color: #ffffff;
  }

  .register_email > input,
  .register_password > input {
    width: 71vw;
    height: 50px;
    margin-top: 3%;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    padding-left: 5%;
    border-color: transparent;
    color: #ffffff;
  }
  ::placeholder {
    width: 156px;
    height: 21px;

    margin-left: 1%;

    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;

    color: rgba(255, 255, 255, 0.2);
  }

  .register_password {
    position: absolute;
    top: 50%;
    left: 10%;
  }
  .forgot_password {
    position: absolute;
    top: 49.7%;
    left: 61%;
  }
  .forgot_password > P {
    width: 100%;
    height: 18px;
    color: rgba(255, 255, 255, 0.5) !important;

    top: 0%;

    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
  .register_register {
    position: absolute;
    top: 68%;
    left: 11%;
  }
  .register_register > p {
    width: 250px;
    height: 18px;
    left: 512px;
    top: 531px;

    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    color: rgba(255, 255, 255, 0.5);
  }

  .register_register > p > span {
    color: #ffffff;
  }
  .register_button {
    position: absolute;
    top: 78%;
    left: 25%;
  }
  .register_button > img {
    width: 70%;
  }
}
